import { render, staticRenderFns } from "./Manager.vue?vue&type=template&id=618a6118&scoped=true"
import script from "./Manager.vue?vue&type=script&lang=js"
export * from "./Manager.vue?vue&type=script&lang=js"
import style0 from "./Manager.vue?vue&type=style&index=0&id=618a6118&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "618a6118",
  null
  
)

export default component.exports